import styled from "styled-components"

import { RowBase } from "./RowBase"

export const Row = styled(RowBase)`
  &:before {
    border-left: 1px solid ${({ color }) => color}9a;
    opacity: 0;
  }
  &:hover:before {
    opacity: 0.6;
  }
  &:focus-within:before {
    opacity: 1;
  }
`
