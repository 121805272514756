import styled from "styled-components"

export const ThemeBase = styled.div`
  width: 900px;
  margin: auto;
  box-sizing: border-box;

  border: 1px solid ${({ color }) => color};
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.12), 1px 1px 3px rgba(0, 0, 0, 0.24);

  padding: 25px;
  margin-bottom: 50px;
`
