import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import ReactContentEditable from "react-contenteditable"
import sanitizeHtml from "sanitize-html"
import { v4 as uuidv4 } from "uuid"
import { Key } from "ts-key-enum"
import styled from "styled-components"

import { ThemeData, BlockData, BlockType } from "../Typings"

import { useTheme } from "./useTheme"
import { ThemeContainer } from "./ThemeContainer"

import { Row } from "Components/Editor/Theme/Row/Row"
import { RowTypeIcon } from "Components/Editor/Theme/Row/RowTypeIcon"
import { NewRowButton } from "Components/Editor/Theme/Row/NewRowButton"

export const buildEmptyThemeData: (themeCode: string) => ThemeData = (
  themeCode,
) => {
  return {
    code: themeCode,
    blocks: [buildEmptyBlockData()],
  }
}

export const buildEmptyBlockData: (blockType?: BlockType) => BlockData = (
  blockType = BlockType.Advice,
) => {
  return {
    id: uuidv4(),
    value: "",
    type: blockType,
  }
}

type Props = {
  initialData: BlockData[]
  onSave: (blockData: BlockData[]) => void
  themeCode: string
}
export const Theme: React.FC<Props> = ({ initialData, onSave, themeCode }) => {
  const data = useRef<BlockData[]>(initialData)
  const [focusIndex, setFocusIndex] = useState<number | null>(null)

  const [themeColor] = useTheme(themeCode)
  const blocksRefs = useRef<HTMLElement[]>([])
  useEffect(() => {
    console.debug("Update refs", { count: data.current.length })
    blocksRefs.current = blocksRefs.current.slice(0, data.current.length)
  }, [data, blocksRefs, focusIndex])

  const updateBlockType = useCallback(
    (index: number, value: BlockType) => {
      console.debug(`Switch row ${index} type to ${value}`)
      data.current[index].type = value
      setFocusIndex(index) // to "blur", call saveData and refresh the DOM
    },
    [data],
  )

  const updateBlockData = useCallback(
    (index: number, value: string) => {
      data.current[index].value = value
    },
    [data],
  )
  const saveData = useCallback(
    (index: number, value: string) => {
      const block = data.current[index]
      if (block !== undefined) {
        block.value = sanitizeHtml(value, {
          allowedTags: [],
        })
      }
      onSave(data.current)
    },
    [data, onSave],
  )
  const addBlock = useCallback(
    (blockType?: BlockType, force = false) => {
      const lastBlockIndex = data.current.length - 1
      const lastBlock = data.current[lastBlockIndex]
      if (!force && !lastBlock.value) return
      const count = data.current.push(buildEmptyBlockData(blockType))
      setFocusIndex(count - 1)
    },
    [data, setFocusIndex],
  )
  const deleteBlock = useCallback(
    (index: number) => {
      data.current.splice(index, 1)
      setFocusIndex(index - 1)
    },
    [data, setFocusIndex],
  )
  // const getBlockById = useCallback(
  //   (id: string) => data.current.find((block) => block.id === id),
  //   [data],
  // )
  const moveCaretToLastPosition = () => {
    const selection: any = document.getSelection()
    console.debug("Selection info", {
      node: selection?.focusNode,
      id: selection?.focusNode?.id,
      length: selection?.focusNode?.length,
      type: typeof selection?.focusNode,
    })
    const targetNode = selection?.focusNode?.lastChild || selection?.focusNode
    selection?.setPosition(targetNode, targetNode?.length)
  }
  const focusBlock = useCallback(
    (index: number) => {
      const blockCount = blocksRefs.current.length
      if (index < 0) return
      if (index > blockCount - 1) {
        addBlock()
      } else {
        // Focus the right block
        blocksRefs.current[index].focus()
        // Move caret to last position
        moveCaretToLastPosition()
      }
    },
    [blocksRefs, addBlock],
  )

  useLayoutEffect(() => {
    if (focusIndex === null) return
    focusBlock(focusIndex)
    setFocusIndex(null)
  }, [focusIndex, focusBlock, setFocusIndex])

  const handleKeyDown = useCallback(
    (index: number) => (event: React.KeyboardEvent<HTMLElement>) => {
      switch (event.key) {
        case Key.Enter:
          event.preventDefault()
          focusBlock(index + 1)
          break
        case Key.ArrowDown:
          event.preventDefault()
          focusBlock(index + 1)
          break
        case Key.ArrowUp:
          event.preventDefault()
          focusBlock(index - 1)
          break
        case Key.Tab:
          event.preventDefault()
          const modifier = event.shiftKey ? -1 : +1
          focusBlock(index + modifier)
          break
        case Key.Backspace:
          const currentBlock = data.current[index]
          const blockValue = sanitizeHtml(currentBlock.value, {
            allowedTags: [],
          })
          if (blockValue.length === 0) {
            event.preventDefault()
            deleteBlock(index)
          }
          break
        case Key.Delete:
          const nextBlock = data.current[index + 1]
          if (nextBlock?.value?.length === 0) {
            event.preventDefault()
            deleteBlock(index + 1)
          }
          break
        default:
          console.debug("Keydown event info", {
            key: event.key,
            metaKey: event.metaKey,
            shiftKey: event.shiftKey,
            ctrlKey: event.ctrlKey,
            altKey: event.altKey,
            location: event.location,
          })
          break
      }
    },
    [focusBlock, deleteBlock],
  )

  const onNewRow = useCallback(
    (blockType: BlockType) => {
      addBlock(blockType, true)
    },
    [addBlock],
  )

  return (
    <ThemeContainer themeCode={themeCode}>
      {data.current.map((block, index) => (
        <Row
          key={block.id}
          onClick={() => focusBlock(index)}
          color={themeColor}
          className={`${BlockType[block.type]}-block-type`}
        >
          <RowTypeIcon
            blockType={block.type}
            color={themeColor}
            onTypeSwitch={(newBlockType) =>
              updateBlockType(index, newBlockType)
            }
          />
          <RowContentEditable
            innerRef={(elt: HTMLElement) => (blocksRefs.current[index] = elt)}
            id={block.id}
            html={block.value}
            onChange={(event) => updateBlockData(index, event.target.value)}
            onBlur={(event) => saveData(index, event.currentTarget.innerHTML)}
            onKeyDown={handleKeyDown(index)}
            onClick={(event) => event.stopPropagation()}
          />
        </Row>
      ))}
      <NewRowButton onNewRow={onNewRow} />
    </ThemeContainer>
  )
}

const RowContentEditable = styled(ReactContentEditable)`
  padding: 15px;
  padding-left: 0px;
  .Advice-block-type > & {
    font-size: 1.1rem;
  }
  .Details-block-type > & {
    font-size: 1rem;
    padding-left: 25px;
    color: #333;
  }
`
