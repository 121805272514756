import React from "react"
import styled from "styled-components"

import { ThemeBase } from "./ThemeBase"
import { ThemeInfo } from "./ThemeInfo"
import { useTheme } from "./useTheme"

type Props = {
  themeCode: string
}
export const ThemeContainer: React.FC<Props> = ({ themeCode, children }) => {
  const [themeColor] = useTheme(themeCode)
  return (
    <ThemeWrapper color={themeColor}>
      <ThemeInfo themeCode={themeCode} />
      {children}
    </ThemeWrapper>
  )
}

const ThemeWrapper = styled(ThemeBase)`
  position: relative;
  padding-top: 60px;
`
