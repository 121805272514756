import React, { useState } from "react"
import styled from "styled-components/macro"
import { useAuth0 } from "@auth0/auth0-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { NavLink, useLocation } from "react-router-dom"

import { LoginButton } from "Components/LoginButton"

type MenuComponentProps = {
  isOpen: boolean
}

type HistoryState = {
  clientName?: string
}

export const Navbar: React.FC = () => {
  const { user, isAuthenticated, logout, isLoading } = useAuth0()
  const [menuOpened, setMenuOpened] = useState(false)
  let { state } = useLocation<HistoryState>()

  return (
    <Nav>
      <Menu>
        {isAuthenticated && (
          <Hamburger
            icon={menuOpened ? faAngleRight : faBars}
            onClick={() => setMenuOpened((menuOpened) => !menuOpened)}
          />
        )}
        <MenuContent isOpen={menuOpened}>
          <LinkItem as={NavLink} to="/">
            Tableau de bord
          </LinkItem>
          <LinkItem as={NavLink} to="/advices-report/test-icons">
            Test icons
          </LinkItem>
          <LinkItem
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Se déconnecter
          </LinkItem>
        </MenuContent>
      </Menu>
      <Title>{isLoading ? "Loading" : state?.clientName}</Title>
      <UserInfo>
        {isAuthenticated ? (
          <>Connecté en tant que {user.name}</>
        ) : (
          <LoginButton />
        )}
      </UserInfo>
    </Nav>
  )
}

const Nav = styled.nav`
  background-color: #fbfbfb;
  height: 90px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Menu = styled.div`
  position: relative;
  padding: 25px;
  width: 50px;
  text-align: center;
`
const Hamburger = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
`
const MenuContent = styled.div<MenuComponentProps>`
  position: absolute;
  height: 100%;
  top: 0;
  left: 75px;
  align-items: center;
  padding: 0px 25px;
  background-color: #fbfbfb;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
`
const LinkItem = styled.a`
  width: 120px;
  padding: 10px;
  margin: 0px 10px;
`
const Title = styled.div`
  text-align: center;
  font-size: 1.2rem;
`
const UserInfo = styled.div`
  padding: 25px;
  width: 250px;
  text-align: right;
  font-size: 0.8rem;
`
