import React, { useMemo } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faThumbtack, faBookmark } from "@fortawesome/free-solid-svg-icons"
import { motion } from "framer-motion"

import { BlockType } from "Components/Editor/Typings"
import { Row } from "./Row"

const BackIconOffset = 3
const BackIconMoveDistance = 2

type Props = {
  color: string
  blockType: BlockType
  onTypeSwitch: (value: BlockType) => void
}
export const RowTypeIcon: React.FC<Props> = ({
  color,
  blockType,
  onTypeSwitch,
}) => {
  const [typeIcon, oppositeTypeIcon, oppositeBlockType] = useMemo(() => {
    switch (blockType) {
      case BlockType.Advice:
        return [faBookmark, faThumbtack, BlockType.Details]
      case BlockType.Details:
        return [faThumbtack, faBookmark, BlockType.Advice]
      default:
        throw new Error(`Unknown or missing blockType: ${blockType}`)
    }
  }, [blockType])

  return (
    <RowTypeIconContainer
      initial={"variantA"}
      whileHover={"variantB"}
      onClick={() => onTypeSwitch(oppositeBlockType)}
    >
      <FrontSideIcon
        variants={{
          variantA: {
            x: 0,
            y: 0,
            zIndex: 10,
            scale: 1,
            opacity: 1,
            transition: {
              zIndex: {
                delay: 0.3,
              },
            },
          },
          variantB: {
            x: 0,
            y: 0,
            zIndex: 5,
            scale: 0.8,
            opacity: 0.3,
            transition: {
              zIndex: {
                delay: 0.3,
              },
            },
          },
        }}
        transition={{
          duration: 1,
          ease: "linear",
        }}
      >
        <FontAwesomeIcon icon={typeIcon} color={color} />
      </FrontSideIcon>
      <BackSideIcon
        variants={{
          variantA: {
            x: [BackIconOffset + BackIconMoveDistance, BackIconOffset],
            y: [BackIconOffset + BackIconMoveDistance, BackIconOffset],
            zIndex: 5,
            scale: 0.8,
            opacity: 0.3,
            transition: {
              zIndex: {
                delay: 0.3,
              },
            },
          },
          variantB: {
            x: [BackIconOffset + BackIconMoveDistance, BackIconOffset],
            y: [BackIconOffset + BackIconMoveDistance, BackIconOffset],
            zIndex: 10,
            scale: 1,
            opacity: 1,
            transition: {
              zIndex: {
                delay: 0.3,
              },
            },
          },
        }}
        transition={{
          duration: 1,
        }}
      >
        <FontAwesomeIcon icon={oppositeTypeIcon} color={color} />
      </BackSideIcon>
    </RowTypeIconContainer>
  )
}

const RowTypeIconContainer = styled(motion.div)`
  position: relative;
  box-sizing: border-box;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 5px;
  height: 35px;
  width: 35px;
  padding: 5px;
  background-color: transparent;
  cursor: pointer;

  opacity: 0;
  ${Row}:hover & {
    opacity: 0.6;
  }
  ${Row}:focus-within & {
    opacity: 1;
  }
`

const FrontSideIcon = styled(motion.div)`
  position: absolute;
  z-index: 10;
  top: 10%;
  left: 30%;
`
const BackSideIcon = styled(motion.div)`
  position: absolute;
  z-index: 5;
  top: calc(10% + ${BackIconOffset}px);
  left: calc(30% + ${BackIconOffset}px);
`
