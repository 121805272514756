export type ThemeData = {
  code: string
  blocks: BlockData[]
}

export enum BlockType {
  Advice,
  Details,
}

export type BlockData = {
  id: string
  value: string
  type: BlockType
}
