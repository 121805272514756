import { useMemo } from "react"
import {
  faUtensils,
  faMedal,
  faPills,
  faSeedling,
  faLeaf,
  faSwimmer,
  faBrain,
  faWind,
  faTint,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons"

/******
 * High contrast palettes :
 * - http://phrogz.net/css/distinct-colors.html
 * - https://graphicdesign.stackexchange.com/questions/3682/where-can-i-find-a-large-palette-set-of-contrasting-colors-for-coloring-many-d
 ******/
export const THEMES_COLOR_PALETTE = [
  "#ff9180",
  "#f2d6b6",
  "#80ffb2",
  "#73cfe6",
  "#464f8c",
  "#f279ea",
  "#d96c7b",
  "#99614d",
  "#ccc566",
  "#4d996b",
  "#46758c",
  "#8273e6",
  "#ffbfea",
  "#e6acb4",
  "#ffb380",
  "#999673",
  "#a3d9c7",
  "#accbe6",
  "#b8a3d9",
  "#bf6093",
  "#a67f53",
  "#b0e673",
  "#40807b",
  "#73a1e6",
  "#75468c",
  "#80606c",
]
export const AdvicesBlockThemes = [
  {
    code: "main-work-axis",
    label: "Axes de travail prioritaires",
    icon: faMedal,
  },
  {
    code: "diet",
    label: "Alimentation",
    icon: faUtensils,
  },
  {
    code: "super-food",
    label: "Super-aliments",
    icon: faSeedling,
  },
  {
    code: "food-complement",
    label: "Compléments alimentaires",
    icon: faPills,
  },
  {
    code: "phyto-aroma",
    label: "Phyto-aromatologie",
    icon: faLeaf,
  },
  {
    code: "physical-activity",
    label: "Exercice physique",
    icon: faSwimmer,
  },
  {
    code: "psycho-relax",
    label: "Psycho-relaxation",
    icon: faBrain,
  },
  {
    code: "breath",
    label: "Respiration",
    icon: faWind,
  },
  {
    code: "hydrology",
    label: "Hydrologie",
    icon: faTint,
  },
]

// const [themeColor, themeLabel, themeIcon] =
export const useTheme = (themeCode: string) =>
  useMemo<[string, string, IconDefinition]>(() => {
    const themeIndex = AdvicesBlockThemes.findIndex(
      (theme) => theme.code === themeCode,
    )
    const theme = AdvicesBlockThemes[themeIndex]
    return [THEMES_COLOR_PALETTE[themeIndex], theme.label, theme.icon]
  }, [themeCode])
