import styled from "styled-components/macro"

export const Input = styled.input`
  display: block;
  padding: 10px;
  margin: 5px;
  border-radius: 4px;
  border: none;
  border: 1px solid #CDBDB6;
  /* background-color: ${(props) => props.theme.colors.primary}; */
`
