import React from "react"
import styled from "styled-components/macro"
import { useAuth0 } from "@auth0/auth0-react"

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()
  return <Button onClick={() => loginWithRedirect()}>Se connecter</Button>
}

const Button = styled.a``
