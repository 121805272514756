import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faParagraph,
  faBookmark,
  faBriefcaseMedical,
  faMedkit,
  faBullhorn,
  faDharmachakra,
  faDotCircle as faDotCircleSolid,
  faExclamation,
  faExclamationCircle,
  faFeather,
  faFeatherAlt,
  faFirstAid,
  faGem,
  faHandHolding,
  faHandHoldingMedical,
  faHandHoldingHeart,
  faHandPointRight,
  faHeart,
  faHeartbeat,
  faICursor,
  faInfo,
  faInfoCircle,
  faKey,
  faLeaf,
  faList,
  faComment,
  faCommentAlt,
  faCommentMedical,
  faCommentDots,
  faListAlt,
  faMapPin,
  faMarker,
  faPaintBrush,
  faPen,
  faPenAlt,
  faPencilAlt,
  faPenFancy,
  faPenNib,
  faPenSquare,
  faNotesMedical,
  faPaperPlane,
  faPaperclip,
  faQuoteLeft,
  faReply, // TODO: rotate
  faScrewdriver,
  faScroll,
  faSeedling,
  faShapes,
  faSlidersH,
  faSpa,
  faStream,
  faSwatchbook,
  faSyringe,
  faTag,
  faTasks,
  faThumbtack,
  faWaveSquare,
} from "@fortawesome/free-solid-svg-icons"
import { faDotCircle as faDotCircleRegular } from "@fortawesome/free-regular-svg-icons"
import {
  faJira,
  faMedapps,
  faMicroblog,
} from "@fortawesome/free-brands-svg-icons"

import { Row } from "Components/Editor/Theme/Row/Row"
import { ThemeBase } from "Components/Editor/Theme/ThemeBase"

const icons = {
  faParagraph,
  faBookmark,
  faBriefcaseMedical,
  faMedkit,
  faBullhorn,
  faDharmachakra,
  faDotCircleSolid,
  faDotCircleRegular,
  faExclamation,
  faExclamationCircle,
  faFeather,
  faFeatherAlt,
  faFirstAid,
  faGem,
  faHandHolding,
  faHandHoldingMedical,
  faHandHoldingHeart,
  faHandPointRight,
  faHeart,
  faHeartbeat,
  faICursor,
  faInfo,
  faInfoCircle,
  faKey,
  faLeaf,
  faList,
  faComment,
  faCommentAlt,
  faCommentMedical,
  faCommentDots,
  faListAlt,
  faMapPin,
  faMarker,
  faPaintBrush,
  faPen,
  faPenAlt,
  faPencilAlt,
  faPenFancy,
  faPenNib,
  faPenSquare,
  faNotesMedical,
  faPaperPlane,
  faPaperclip,
  faQuoteLeft,
  faReply, // TODO: rotate
  faScrewdriver,
  faScroll,
  faSeedling,
  faShapes,
  faSlidersH,
  faSpa,
  faStream,
  faSwatchbook,
  faSyringe,
  faTag,
  faTasks,
  faThumbtack,
  faWaveSquare,
  faJira,
  faMedapps,
  faMicroblog,
}

const ToRotate: { [key: string]: 90 | 180 | 270 | undefined } = {
  faReply: 180,
}

export const IconsPage: React.FC = () => {
  const rows = Object.entries(icons).map(([code, icon]) => (
    <Row color="#800080" key={code}>
      <TypeIcon
        icon={icon}
        color="#800080"
        rotation={ToRotate[code] || undefined}
      />
      <div>{code}</div>
    </Row>
  ))
  return <ThemeBase>{rows}</ThemeBase>
}

const TypeIcon = styled(FontAwesomeIcon)`
  margin: 0px 15px;
  font-size: 1.3rem;
  color: ${({ color }) => color}9a;
`
