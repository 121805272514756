import React, { useCallback } from "react"
import styled, { css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconDefinition } from "@fortawesome/fontawesome-svg-core"

type ThemeSelectorItemProps = {
  isUsed: boolean
  onSelect: (themeCode: string) => void
  themeCode: string
  themeColor: string
  themeIcon: IconDefinition
  themeLabel: string
}
export const ThemeSelectorItem: React.FC<ThemeSelectorItemProps> = ({
  isUsed,
  onSelect,
  themeCode,
  themeColor,
  themeIcon,
  themeLabel,
}) => {
  const onThemeSelected = useCallback(() => onSelect(themeCode), [
    onSelect,
    themeCode,
  ])
  return (
    <ThemeSelectorItemWrapper
      color={themeColor}
      disabled={isUsed}
      onClick={isUsed ? undefined : onThemeSelected}
    >
      <ThemeIcon icon={themeIcon} size="2x" />
      <ThemeLabel>{themeLabel}</ThemeLabel>
    </ThemeSelectorItemWrapper>
  )
}

interface ThemeSelectorItemWrapperProps {
  readonly disabled: boolean
}
const ThemeSelectorItemWrapper = styled.div<ThemeSelectorItemWrapperProps>`
  width: 120px;
  height: 110px;

  padding: 10px;
  margin: 15px;

  text-align: center;

  border-radius: 10px;

  ${({ disabled, color }) =>
    disabled
      ? css`
          cursor: default;
          color: ${color}5a;
          background-color: ${color}0a;
        `
      : css`
          cursor: pointer;
          color: ${color};
          background-color: ${color}1a;
          /* border: 1px solid ${color}5a; */
          &:hover {
            background-color: ${color}3a;
          }
        `}
`
const ThemeIcon = styled(FontAwesomeIcon)`
  margin: 15px;
`
const ThemeLabel = styled.div``
