import styled from "styled-components"

export const RowBase = styled.div`
  height: 50px;

  display: flex;
  justify-content: stretch;
  align-items: center;
  margin-left: 50px;
  box-sizing: border-box;

  border: 1px solid #00000000;

  &:before {
    content: "";
    height: 40px;
    border-left: 1px solid #00000000;
  }
`
