import React from "react"
import styled from "styled-components"

import { ThemeBase } from "Components/Editor/Theme/ThemeBase"
import {
  AdvicesBlockThemes,
  THEMES_COLOR_PALETTE,
} from "Components/Editor/Theme/useTheme"

import { ThemeSelectorItem } from "./ThemeSelectorItem"

type ThemeSelectorProps = {
  usedThemes: string[]
  onThemeSelected: (themeCode: string) => void
}
export const ThemeSelector: React.FC<ThemeSelectorProps> = ({
  usedThemes,
  onThemeSelected,
}) => {
  return (
    <ThemeSelectorWrapper color="#8000803a">
      {AdvicesBlockThemes.map((theme, index) => {
        const isUsed =
          usedThemes.findIndex((themeCode) => themeCode === theme.code) >= 0
        const themeColor = THEMES_COLOR_PALETTE[index]
        return (
          <ThemeSelectorItem
            key={theme.code}
            themeCode={theme.code}
            themeIcon={theme.icon}
            themeLabel={theme.label}
            themeColor={themeColor}
            isUsed={isUsed}
            onSelect={onThemeSelected}
          />
        )
      })}
    </ThemeSelectorWrapper>
  )
}

const ThemeSelectorWrapper = styled(ThemeBase)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  padding: 15px;
`
