import React, { useCallback, useState } from "react"
import styled from "styled-components"

import { BlockData, ThemeData } from "Components/Editor/Typings"
import { Theme, buildEmptyThemeData } from "Components/Editor/Theme/Theme"
import { AddThemeButton } from "Components/Editor/Theme/ThemeSelector/AddThemeButton"

// const logData = (data: BlockData[]) => {
//   console.group("Log data")
//   data.forEach((block) => {
//     console.log({
//       id: block.id,
//       value: block.value,
//     })
//   })
//   console.groupEnd()
// }

export const AdvicesEditorPage: React.FC = () => {
  const [data, setData] = useState<ThemeData[]>(() => {
    const storedData = localStorage.getItem("editorData")
    return storedData ? JSON.parse(storedData) : []
  })

  const onThemeSave = useCallback(
    (themeIndex) => (blockData: BlockData[]) => {
      setData((data) => {
        const newData = [...data]
        newData[themeIndex].blocks = blockData
        localStorage.setItem("editorData", JSON.stringify(newData))
        return newData
      })
    },
    [setData],
  )
  const onNewTheme = useCallback(
    (themeCode) => {
      setData((data) => {
        if (data.findIndex((theme) => theme.code === themeCode) >= 0)
          return data
        const newData = [...data, buildEmptyThemeData(themeCode)]
        localStorage.setItem("editorData", JSON.stringify(newData))
        return newData
      })
    },
    [setData],
  )

  return (
    <Editor>
      {data.map((themeData, index) => (
        <Theme
          key={themeData.code}
          initialData={themeData.blocks}
          onSave={onThemeSave(index)}
          themeCode={themeData.code}
        />
      ))}
      <AddThemeButton data={data} onNewTheme={onNewTheme} />
    </Editor>
  )
}

const Editor = styled.div`
  padding-bottom: 100px;
`
