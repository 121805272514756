import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useTheme } from "./useTheme"

type Props = {
  themeCode: string
}
export const ThemeInfo: React.FC<Props> = ({ themeCode }) => {
  const [themeColor, themeLabel, themeIcon] = useTheme(themeCode)
  return (
    <ThemeInfoWrapper color={themeColor}>
      <ThemeIconWrapper color={themeColor}>
        <ThemeIcon icon={themeIcon} size="lg" />
      </ThemeIconWrapper>
      <ThemeLabel>{themeLabel}</ThemeLabel>
    </ThemeInfoWrapper>
  )
}

const ThemeInfoWrapper = styled.div`
  display: flex;
  align-items: center;

  position: absolute;
  left: -35px;
  top: -10px;

  color: ${({ color }) => color};
`

const ThemeIconWrapper = styled.div`
  width: 70px;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 150px;

  background-color: ${({ color }) => color};
`

const ThemeIcon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 1.7rem;
`

const ThemeLabel = styled.span`
  margin-left: 14px;
  font-size: 1.8rem;
`
