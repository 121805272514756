import React, { PropsWithChildren } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

import { Navbar } from "Components/Navbar"
import { AdvicesEditorPage } from "Pages/advices-report/editor"
import { DashboardPage } from "Pages/dashboard"
import { IconsPage } from "Pages/advices-report/icons"

const AppContent = () => (
  <Router>
    <Navbar />
    <Switch>
      <PrivateRoute path="/advices-report/editor">
        <AdvicesEditorPage />
      </PrivateRoute>
      <Route path="/advices-report/test-icons">
        <IconsPage />
      </Route>
      <Route path="/">
        <DashboardPage />
      </Route>
    </Switch>
  </Router>
)

function App() {
  const { isLoading } = useAuth0()
  return isLoading ? <span>Loading ...</span> : <AppContent />
}

function PrivateRoute<P = {}>({ children, ...params }: PropsWithChildren<P>) {
  const { isAuthenticated } = useAuth0()
  return (
    <Route
      {...params}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default App
