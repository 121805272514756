import React, { useCallback, useState, useMemo } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"

import { ThemeData } from "Components/Editor/Typings"

import { ThemeSelector } from "./ThemeSelector"

type Props = {
  data: ThemeData[]
  onNewTheme: (themeCode: string) => void
}
export const AddThemeButton: React.FC<Props> = ({ data, onNewTheme }) => {
  const [displayThemeChooser, setDisplayThemeChooser] = useState(false)
  const toggleThemeChooser = useCallback(() => {
    setDisplayThemeChooser((displayThemeChooser) => !displayThemeChooser)
  }, [setDisplayThemeChooser])

  const usedThemes = useMemo(() => data.map((themeData) => themeData.code), [
    data,
  ])

  const handleAddTheme = useCallback(
    (themeCode: string) => {
      onNewTheme(themeCode)
      toggleThemeChooser()
    },
    [onNewTheme, toggleThemeChooser],
  )

  return displayThemeChooser ? (
    <ThemeSelector usedThemes={usedThemes} onThemeSelected={handleAddTheme} />
  ) : (
    <OpenThemeSelectorButton
      icon={faPlusCircle}
      onClick={toggleThemeChooser}
      size="3x"
    />
  )
}

const OpenThemeSelectorButton = styled(FontAwesomeIcon)`
  display: block;
  margin: auto;

  cursor: pointer;

  color: ${(props) => props.theme.colors.primary};
`
