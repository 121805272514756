import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faThumbtack,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons"
import { motion, useCycle } from "framer-motion"

import { RowBase } from "Components/Editor/Theme/Row/RowBase"
import { BlockType } from "Components/Editor/Typings"

type Props = {
  onNewRow: (blockType: BlockType) => void
}
const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
}
export const NewRowButton: React.FC<Props> = ({ onNewRow }) => {
  const [animate, cycle] = useCycle({ rotate: 0 }, { rotate: 45 })
  const [opened, setOpened] = useState(false)
  const handleNewRowClick = useCallback(() => {
    cycle()
    setOpened((opened) => !opened)
  }, [cycle, setOpened])
  const handleSelectType = useCallback(
    (blockType) => () => {
      onNewRow(blockType)
      handleNewRowClick()
    },
    [onNewRow, handleNewRowClick],
  )

  return (
    <NewRowButtonWrapper>
      <motion.div animate={animate} onTap={handleNewRowClick}>
        <AddRowButton icon={faPlus} />
      </motion.div>
      <TypeSelector
        variants={variants}
        initial="hidden"
        animate={opened ? "visible" : "hidden"}
      >
        <RowTypeButton
          icon={faBookmark}
          onClick={handleSelectType(BlockType.Advice)}
        />
        <RowTypeButton
          icon={faThumbtack}
          onClick={handleSelectType(BlockType.Details)}
        />
      </TypeSelector>
    </NewRowButtonWrapper>
  )
}

const NewRowButtonWrapper = styled(RowBase)``

const RowTypeButton = styled(FontAwesomeIcon)`
  margin-right: 15px;
  padding: 15px;

  display: block;
  width: 13px !important;
  height: 13px !important;

  border-radius: 100px;
  font-size: 1.3rem;
  color: #8080809a;
  cursor: pointer;

  &:hover {
    background-color: #8080803a;
  }
  &:last-child {
    margin-right: 0;
  }
`
const TypeSelector = styled(motion.div)`
  display: flex;
  overflow: hidden;

  margin-left: 15px;

  & ${RowTypeButton} {
    font-size: 0.8rem;
  }

  background-color: #8080801a;
  border-radius: 100px;
`

const AddRowButton = styled(FontAwesomeIcon)`
  display: flex;
  overflow: hidden;
  margin-left: 2px;
  padding: 15px;

  font-size: 0.8rem;

  color: #8080808a;
  border-radius: 100px;
  cursor: pointer;
  &:hover {
    background-color: #8080801a;
  }
`
