import styled from "styled-components/macro"

export const Button = styled.button`
  padding: 15px;
  margin: 5px;
  border-radius: 4px;
  border: none;
  box-shadow: 0px 2px 2px 1px #00000044;
  color: ${(props) => props.theme.revertColors.primary};
  background-color: ${(props) => props.theme.colors.primary};
`
