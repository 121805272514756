import React, { useState } from "react"
import styled from "styled-components"
import { useHistory } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

import { Button } from "Components/Basics/Button"
import { Input } from "Components/Basics/Input"

export const DashboardPage = () => {
  const { isAuthenticated } = useAuth0()
  const [clientName, setClientName] = useState<string>("")
  let history = useHistory()

  return isAuthenticated ? (
    <PageContainer>
      <Form
        onSubmit={() =>
          history.push("/advices-report/editor", {
            clientName,
          })
        }
      >
        <ClientName
          type="text"
          placeholder="nom du client"
          name="client-name"
          value={clientName}
          onChange={(event) => setClientName(event.target.value)}
        />
        <Submit type="submit">Nouveau programme conseil</Submit>
      </Form>
    </PageContainer>
  ) : (
    <PageContainer>Connexion requise</PageContainer>
  )
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ClientName = styled(Input)``
const Submit = styled(Button)`
  width: 200px;
`
